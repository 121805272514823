
/* DataTableDemo.css */

.datatable-crud{
    margin: 9px;
    padding: 7px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 1);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 1);
    border-radius: 10px;
    // width: 84vw !important;
    width: calc(100vw - 270px);
    // height: 65vh;
    // width: 100%;

    // .p-datatable {
    //     height: 59vh;
    // }

    .p-datatable-wrapper{
        // height: 48vh;
        height: calc(100vh - 450px);
    }

    .p-selectable-row{
        height: 3rem;
    }

    .p-datatable-tbody{
        font-size: 11px;
        // font-weight: 400;
    }
}

.dropdown-toggle.btn.btn-primary{
    width: 25px;
    height: 25px;
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0.175rem;
//     box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.51);
// -webkit-box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.51);
// -moz-box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.51)
}



.datatable-crud .table-header {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // padding-top: 20px;
    // padding-bottom: 10px;
}
@media screen and (max-width: 960px) {
    .datatable-crud .table-header {
        align-items: flex-start;
    }
}
.datatable-crud .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud .table-header {
        flex-direction: column;
    }
    .datatable-crud .table-header .p-input-icon-left, .datatable-crud .table-header input {
        width: 100%;
    }
}

.p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0px !important;
}


.p-inputtext.p-component.p-column-filter {
    padding: 0.25rem !important;
    margin: 2px;
    height: 1.4rem !important;
    font-size: 11px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover{
    width: 1.5rem;
    height: 1.5rem;
}

.p-button.p-component.p-button-rounded.p-button-warning.p-button-icon-only{
    width: 1.5rem;
    height: 1.5rem;
}

.p-button.p-component.p-button-rounded.p-button-success.mr-2.p-button-icon-only{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 8px;

}
.pi-trash:before, .pi-pencil:before {
    padding-bottom: 2px;
    font-size: small;
}

.pi-filter:before, .pi-filter-slash:before {
    font-size: 0.65rem !important;
}

.p-toolbar.p-component.mb-4 {
    padding: .5rem;
}

.p-button.p-component.p-button-success.mr-2{
    margin-right: 10px;
}

.p-fileupload.p-fileupload-basic.p-component.mr-2.inline-block, .p-button.p-component.p-button-help.export-custom{
    margin-right: 10px;
}

.p-button.p-component.p-fileupload-choose, .p-button.p-component.p-button-success.new-add-custom, .p-button.p-component.p-button-danger.delete-custom, .p-button.p-component.p-button-help.export-custom, .p-button.p-component.p-button-info.save-custom{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    padding: 0px;
    // border-radius: 50px !important;
//     box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.51);
// -webkit-box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.51);
// -moz-box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.51)
}

// .p-button {
//     width: 2.5rem;
//     height: 2.5rem;
// }

.p-button-label.p-clickable {
    display: none;
}

.pi {
    font-size: 0.75rem !important; 
}

.add_trxn_input_label{
    font-weight: 600;

}

.pi-upload:before{
    padding-left: 0.35rem;
}

#customer_id, #customer_name, #trxn_date, #metal, #trxn_type, #metal_wght, #uow, #fine_percent, #service_rate, #service_wght, #overridden_service_wght, #service_chrg, #overridden_service_chrg, .p-inputgroup-addon{
    // padding: 5px 10px;
    height: 40px;
}

.p-dialog-content {
    .field{
        padding-top: 20px;
        font-weight: 600;
        .p-dropdown-label.p-inputtext {
            padding-left: 12px;
            display: flex;
            align-items: center;
            justify-content: left;
        }
    }
}

.wght_section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wght_left, .wght_right{
        width: 45%;
    }
}

.product-badge{
    padding: 3px 5px 0px 5px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: bolder;
}

.product-badge.status-pending_approval{
    background-color: rgb(255, 253, 201);
    color: rgba(250, 205, 5, 0.967);
}

.product-badge.status-deleted{
    background-color: rgb(243, 192, 192);
    color: rgb(252, 54, 54);
}

.product-badge.status-approved{
    background-color:  rgb(144, 252, 151);
    color: rgb(11, 188, 11);
}







.file-upload-button{
    
}

.p-toolbar-group-left {
    h4 {
        font-size: 1.3rem;
        font-weight: 600;
    }


}

.p-toolbar.p-component.mb-4{
    margin-bottom: 0px !important;
    padding: 0 20px 0 10px;
}

.p-datatable-tbody{
    tr{
        height: 1.8rem;
    }

    .added-trxns{
        background-color: lightgreen !important;
    }

    .updated-trxns {
        background-color: lightgoldenrodyellow !important;
    }

    .deleted-trxns {
        background-color: lightsalmon !important;
    }
}

.p-datatable-thead {
    tr {
        height: 1.8rem;
    }

    .p-column-header-content {
        .p-column-title{
            font-size: 12px !important;
        }
    }
}

.p-filter-column.p-frozen-column{
    z-index: -1 !important;
}

.dropdown-item{
    font-size: 12px;

    .switch.btn-xs{
        width: 2.125rem;
        min-width: 2.125rem;
        height: 1.175rem;
        min-height: 1.175rem;
    }

    .switch-on.btn.btn-primary.btn-xs, .switch-off.btn.btn-light.btn-xs{
        padding: 0px;
        font-size: 11px;
    }

    .col-header-name{
        padding-left: 8px;
    }
}


//Footer Styling

.p-paginator.p-component.p-paginator-bottom {
    height: 2rem;
    padding: 0px;
    border-top: 1px solid lightgray !important;

    .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last{
        width: 25px;
        height: 25px;
        padding: 0px;
    }

    .p-paginator-pages {
        width: auto;
        height: 25px;        
    }

    .p-paginator-current {
        height: 25px;
        font-size: 12px;
        font-weight: 600;
    }

    .p-paginator-pages {
        .p-paginator-page {
            min-width: 25px !important;
            width: 25px !important;
            height: 25px !important;
            margin: 0px !important;
        }
    }

    .p-dropdown {
        width: 70px;
        height: 25px !important;

        .p-inputtext{
            padding: 0.2rem 0.5rem;
            font-size: 12px;
        }

        .p-dropdown-trigger {
            width: 2rem;
        }
    }

}

.my_date_range{
    padding: 0px 20px;
    font-size: 14px !important;

    .trxn_date_range_class{
        // padding-left: 20px !important;
        padding-right: 10px;
        font-weight: bold;
        padding-bottom: 3px;
    }
    #trxn_date_range{
        height: 20px;

        .p-inputtext.p-component.p-filled {
            font-size: 12px;
        }
    }
}

.dropdown {
    .dropdown-menu {
        overflow: auto;
        max-height: 19.4rem;
    }

}